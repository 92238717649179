import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AgendaWebHome.module.css';
import imgAgendaWeb from '../../assets/agenda-web-img.png';
import ScrollTrigger from 'react-scroll-trigger';

const AgendaWebHome = () => {
  const myElementRef = React.useRef(null);

  return (
    <div className={styles.resumo} ref={myElementRef}>
      {myElementRef ? (
        <ScrollTrigger
          onEnter={() => {
            myElementRef.current.classList.add('animeLeft');
          }}
        />
      ) : (
        ''
      )}

      <div className={styles.texto}>
        <h2 className={styles.subTitulo}>VISUALIZE SUA AGENDA NA WEB</h2>
        <h1 className={styles.titulo}>
          praticidade ao alcance do seu smartphone<span>.</span>
        </h1>
        <p className={styles.desc}>
          A <span className={styles.spanTexto}>revolução na gestão</span>! Nosso
          novo módulo <span className={styles.spanTexto}>AgendaWeb</span>{' '}
          permite o acesso de sua agenda{' '}
          <span className={styles.spanTexto}>em qualquer lugar</span>. Integrado
          com o módulo de Atendimento Desktop, permite o{' '}
          <span className={styles.spanTexto}>
            registro detalhado da evolução
          </span>{' '}
          do paciente e{' '}
          <span className={styles.spanTexto}>
            registro de atendimento do paciente
          </span>
          . Seguro e intuitivo,{' '}
          <span className={styles.spanTexto}>
            modernize seu trabalho hoje mesmo
          </span>
          !
        </p>
        <div className={styles.divButton}>
          <Link to="/produtos/agendaweb" className={styles.saibaMaisLink}>
            <button className={styles.saibaMais}>SAIBA MAIS</button>
          </Link>
        </div>
      </div>
      <img
        src={imgAgendaWeb}
        alt="Imagem da logo da Agenda Web"
        className={styles.imgWpp}
      />
    </div>
  );
};

export default AgendaWebHome;
