import glosaXml from '../../assets/glosaXml.svg';
import glosa from '../../assets/glosa.svg';
import repasse from '../../assets/repasse.svg';
import recuperacao from '../../assets/recuperacao.svg';
import simpro from '../../assets/simpro.svg';
import xmlEstoque from '../../assets/xmlEstoque.svg';
import alarme from '../../assets/alarme.svg';
import produtos from '../../assets/produtos.svg';
import fornecedores from '../../assets/fornecedores.svg';
import entraSai from '../../assets/entraSai.svg';
import agenda from '../../assets/agenda.svg';
import paciente from '../../assets/paciente.svg';
import prontuario from '../../assets/prontuario.svg';
import receita from '../../assets/receita.svg';
import exame from '../../assets/exame.svg';
import atestado from '../../assets/atestado.svg';
import relatorio from '../../assets/relatorio.svg';
import caixa from '../../assets/caixa.svg';
import gestao from '../../assets/gestao.svg';
import malaDireta from '../../assets/maladireta.svg';
import guiasTiss from '../../assets/guiastiss.svg';
import outros from '../../assets/outros.svg';
import grafico from '../../assets/grafico.svg';
import dmed from '../../assets/dmed.svg';
import sms from '../../assets/sms.svg';
import senha from '../../assets/senha.svg';
import orizon from '../../assets/orizon.svg';
import wpp from '../../assets/wpp.svg';
import qrcode from '../../assets/qrcode.svg';
import orto from '../../assets/orto.svg';
import atendimento from '../../assets/atendimento.jpg';
import faturamento from '../../assets/faturamento.jpg';
import financeiro from '../../assets/financeiro.jpg';
import nfse from '../../assets/nfse.jpg';
import estoque from '../../assets/estoque.jpg';
import integracao from '../../assets/integracao.svg';
import banco from '../../assets/banco.svg';
import pessoa from '../../assets/pessoa.svg';
import empresa from '../../assets/empresa.svg';
import mail from '../../assets/mail.svg';
import atendimentoVideo from '../../assets/atendimento.mp4';
import faturamentoVideo from '../../assets/faturamento.mp4';
import estoqueVideo from '../../assets/estoque.mp4';
import financeiroVideo from '../../assets/financeiro.mp4';
import nfseVideo from '../../assets/NFSe.mp4';

const modulos = [
  {
    nome: 'atendimento',
    id: 1,
    desc: 'O nosso módulo de Atendimento é uma ferramenta completa que oferece diversas funcionalidades essenciais para garantir o bom funcionamento da sua clínica e a gestão eficiente de dados importantes para a definição de métricas. Com ele, você poderá aprimorar o atendimento aos seus pacientes e otimizar os processos internos da sua clínica. Com uma interface intuitiva e fácil de usar, o nosso módulo de Atendimento é a escolha perfeita para tornar o seu dia a dia mais prático e eficiente.',
    descHome:
      'Nosso módulo de atendimento conta com diversas funcionalidades essenciais para o bom funcionamento da sua clínica e sua gestão de dados indispensáveis para definição de métricas.',
    media: atendimentoVideo,
    img: atendimento,
    pontosChave: [
      'Controle de Agenda',
      'Prontuário Eletrônico',
      'Relatórios variados',
      'Confirmação via Whatsapp',
    ],
    pontos: [
      {
        ponto: 'Agendamento de Pacientes',
        svg: agenda,
      },
      {
        ponto: 'Cadastro de Pacientes',
        svg: paciente,
      },
      {
        ponto: 'Prontuário Eletrônico (personalizados)',
        svg: prontuario,
      },
      {
        ponto: 'Emissão de Receituário  ',
        svg: receita,
      },
      {
        ponto: 'Emissão de Pedido de Exames',
        svg: exame,
      },
      {
        ponto: 'Emissão de Atestados',
        svg: atestado,
      },
      {
        ponto: 'Emissão de Relatórios Médicos',
        svg: relatorio,
      },
      {
        ponto: 'Controle de Caixa',
        svg: caixa,
      },
      {
        ponto: 'Relatórios variados de gestão',
        svg: gestao,
      },
      {
        ponto: 'Mala direta de Pacientes',
        svg: malaDireta,
      },
      {
        ponto: 'Impressão de Guias TISS com logo',
        svg: guiasTiss,
      },
      {
        ponto: 'Impressão de Guias outros modelos ',
        svg: outros,
      },
      {
        ponto: 'Gráfico da evolução do paciente',
        svg: grafico,
      },
      {
        ponto: 'Geração de arquivo DMED',
        svg: dmed,
      },
      {
        ponto: 'Integração Software de SMS',
        svg: sms,
      },
      {
        ponto: 'Integração Software de Senha',
        svg: senha,
      },
      {
        ponto: 'Integração Autorizações Orizon',
        svg: orizon,
      },
      {
        ponto: 'API de integração com Whatsapp',
        svg: wpp,
      },
      {
        ponto: 'Emissão de Receitas Digitais (QrCode)',
        svg: qrcode,
      },
      {
        ponto: 'MSWORD® Verificação Ortográfica',
        svg: orto,
      },
      {
        ponto: 'Integração com Todos os Módulos',
        svg: integracao,
      },
    ],
  },
  {
    nome: 'faturamento',
    id: 2,
    desc: 'Graças à nossa vasta experiência de mais de 10 anos no mercado de faturamento médico, nosso módulo de Faturamento oferece o que há de mais avançado em relação ao controle de guias, faturas, glosas, repasses médicos, geração de XML-TISS e outras funcionalidades essenciais. Com o nosso módulo de Faturamento, você poderá otimizar o processo de faturamento médico da sua clínica, aumentar a eficiência e reduzir os custos operacionais.',
    descHome:
      'Devido a nossa experiência de mais de 10 anos com o mercado de faturamento médico, nosso módulo de Faturamento tem o que há de melhor em relação a controle de guias, faturas, glosas, repasses médicos, envio de xml e muito mais.',
    media: faturamentoVideo,
    img: faturamento,
    pontosChave: [
      'Envio de XML TISS',
      'Repasses Configuráveis',
      'Recurso de Glosas',
      'Total Controle das Guias',
    ],
    pontos: [
      {
        ponto: 'Digitação Guias Padrão TISS',
        svg: guiasTiss,
      },
      {
        ponto: 'Digitação Guias Outros Modelos',
        svg: outros,
      },
      {
        ponto: 'Geração XML padrão TISS',
        svg: dmed,
      },
      {
        ponto: 'Controle Glosas e Recuperações',
        svg: recuperacao,
      },
      {
        ponto: 'Quitação de Fatura ou Guias',
        svg: caixa,
      },
      {
        ponto: 'Repasse Honorários Médicos',
        svg: repasse,
      },
      {
        ponto: 'Relatório Variados de Faturas',
        svg: gestao,
      },
      {
        ponto: 'Importação de Guias do Atendimento',
        svg: integracao,
      },
      {
        ponto: 'Importação Brasíndice/SIMPRO',
        svg: simpro,
      },
      {
        ponto: 'XML Demonstrativo de Glosa',
        svg: glosaXml,
      },
      {
        ponto: 'XML de Recuperação de Glosas',
        svg: glosa,
      },
    ],
  },
  {
    nome: 'financeiro',
    id: 3,
    desc: 'Com o nosso módulo de Financeiro, você terá acesso a relatórios que irão lhe informar sobre a saúde financeira do seu negócio em apenas alguns cliques. O módulo de Financeiro é uma ferramenta essencial para auxiliá-lo a manter o controle financeiro da sua empresa de forma eficiente e descomplicada. Não perca mais tempo com processos manuais e tenha sempre em mãos as informações financeiras que precisa para tomar as melhores decisões.',
    descHome:
      'Nosso módulo de Financeiro auxilia o seu negócio a sempre ter ao alcance de poucos cliques relatórios que lhe informam como está a sua saúde financeira.',
    media: financeiroVideo,
    img: financeiro,
    pontosChave: [
      'Extratos por conta',
      'Balanço Receitas/Depesas',
      'Fluxo de Caixa',
      'Importação de extrato',
    ],
    pontos: [
      {
        ponto: 'Controle do Fluxo de Caixa',
        svg: caixa,
      },
      {
        ponto: 'Controle de Receitas/Despesas',
        svg: gestao,
      },
      {
        ponto: 'Contas a Pagar/Receber',
        svg: repasse,
      },
      {
        ponto: 'Conciliação Bancária',
        svg: banco,
      },
      {
        ponto: 'Integração Atendimento e Faturamento',
        svg: integracao,
      },
      {
        ponto: 'Importação de Extrato da Conta',
        svg: xmlEstoque,
      },
    ],
  },
  {
    nome: 'estoque',
    id: 4,
    desc: 'Com o nosso módulo de Estoque, você terá sempre visibilidade do que está disponível em seu negócio. O uso do módulo é extremamente simples e intuitivo, facilitando o controle e permitindo que você tenha uma gestão eficiente do seu estoque. Não perca mais tempo com controles manuais, deixe o nosso módulo de Estoque auxiliá-lo nessa tarefa.',
    descHome:
      'Saiba sempre o que tem disponível no estoque do seu négocio com o nosso módulo de Estoque, de uso muito simples e intuitivo, nosso módulo de Estoque irá auxiliá-lo nesse controle',
    media: estoqueVideo,
    img: estoque,
    pontosChave: [
      'Solicitação por setor',
      'Controle Entrada/Saída',
      'Alerta de Baixo Estoque',
      'Alerta de vencimentos',
    ],
    pontos: [
      {
        ponto: 'Cadastro de Produtos',
        svg: produtos,
      },
      {
        ponto: 'Cadastro de Fornecedores',
        svg: fornecedores,
      },
      {
        ponto: 'Controle Entrada/Saída Produtos',
        svg: entraSai,
      },
      {
        ponto: 'Alarme para Estoque Reduzido',
        svg: alarme,
      },
      {
        ponto: 'Relatórios Gerenciais',
        svg: gestao,
      },
      {
        ponto: 'Leitura XML Entrada no Estoque',
        svg: xmlEstoque,
      },
      {
        ponto: 'Controle Solicitações de Produtos',
        svg: relatorio,
      },
    ],
  },
  {
    nome: 'NFS-e',
    id: 5,
    desc: 'Com o nosso módulo de NFS-e, você poderá emitir notas fiscais de serviços de forma fácil e eficiente no dia a dia do seu negócio. O módulo é totalmente integrado com nossos módulos de Atendimento e Faturamento, o que possibilita a emissão simplificada, aproveitando informações importantes dos cadastros de pacientes e convênios.',
    descHome:
      'O módulo de NFS-e irá facilitar a emissão da notas no dia a dia do seu negócio, com integração com nossos módulos de Atendimento e Faturamento, para uma emissão facilitada aproveitando dados essenciais dos cadastros de pacientes e convênios.',
    media: nfseVideo,
    img: nfse,
    pontosChave: [
      'Pessoa Física e Jurídica',
      'Envio da NFS-e por e-mail',
      'DANFSE com QRCode',
      'XML em lote - Contabilidade',
    ],
    pontos: [
      {
        ponto: 'Emissão para Pessoa Física',
        svg: pessoa,
      },
      {
        ponto: 'Emissão para Pessoa Jurídica',
        svg: empresa,
      },
      {
        ponto: 'NFS-e por e-mail aos Pacientes',
        svg: mail,
      },
      {
        ponto: 'Relatório Notas Emitidas por Emitente',
        svg: gestao,
      },
      {
        ponto: 'Extração NFS-e em XML por Emitente',
        svg: dmed,
      },
      {
        ponto: 'Extração DANFS-e em PDF por Emitente',
        svg: guiasTiss,
      },
      {
        ponto: 'Integração Faturamento e Atendimento',
        svg: integracao,
      },
      // {
      //   ponto: 'Inutilização, Cancelamento e Carta de Correção',
      //   svg: entraSai,
      // },
    ],
  },
];

export default modulos;
